import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { DefaultHeader } from "components/headers/default-header"
import { graphql } from "gatsby"
import { Link } from "gatsby"

const Sitemap = ({ location, data }) => {
  const {
    allSitemapJson: { edges: sitemaps },
  } = data
  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle="Site Map"
          pagepath={location.pathname}
          pagedesc="Site Map | PARKLoT offers a Twitter campaign tool that can be run as many times as you want for a monthly fee."
        />
        <main className="information">
          <section className="information__heading py-10 px-4 md:pt-14 md:pb-16 md:px-0">
            <div className="container">
              <h1 className="text-white font-bold">Site Map</h1>
            </div>
          </section>
          <section className="information__philosophy bg-white">
            <div className="container py-10 px-4 md:py-14 md:px-0 ma-auto">
              {sitemaps.map((sitemap, index) => {
                const site = sitemap.node
                return (
                  <div key={index} className="mb-16">
                    <h2 className="text-xl font-bold">{site.title}</h2>
                    <ul className="mt-5">
                      <div className="md:grid grid-cols-2 gap-1">
                        {site.subPages.map((subPage, k) => {
                          return (
                            <li className="mt-3 pr-4" key={k}>
                              <Link to={subPage.src}>・{subPage.title}</Link>
                            </li>
                          )
                        })}
                      </div>
                    </ul>
                  </div>
                )
              })}
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query GetSitemaps {
    allSitemapJson {
      edges {
        node {
          title
          id
          subPages {
            external
            src
            title
          }
        }
      }
    }
  }
`

export default Sitemap
